import React from 'react';
import { motion } from 'framer-motion';
import { faCommentDots, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWebSettings } from '../../context/WebSettingsContext';
import Spinner from '../common/Spinner';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { webData, loading } = useWebSettings();
  const userLanguage = navigator.language;
  const mapSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1950.4578053749635!2d24.768096576872356!3d60.734398475325044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468e138367935353%3A0xb7e2fa3b2da8285b!2sAlanya%20Kebab%20Pizza!5e0!3m2!1s${userLanguage}!2s${userLanguage}!4v1730479718029!5m2!1s${userLanguage}!2s${userLanguage}`;
  const { t } = useTranslation();
  if (loading) {
    return <Spinner />;
  }
  return (
    <motion.div
      className="text-stone-900 dark:text-zinc-300 p-4 w-full font-sans"
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h1
        className="mt-4 text-[35px] font-bold"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5 }}
      >
        {t('ContactInformation')}
      </motion.h1>
      <motion.div
        className="mt-8 w-full bg-slate-200 dark:bg-stone-800 p-6 rounded-md flex flex-col md:flex-row"
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-6 md:w-1/2 px-4">
          <div className="flex flex-col gap-6 mt-4">
            <div className="flex flex-col">
              <motion.div
                className="flex flex-row text-[25px]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <FontAwesomeIcon
                  icon={faCommentDots}
                  className="text-stone-900 dark:text-orange-400 pt-2 mr-4"
                />
                <div>
                  <p className="flex justify-start items-center font-semibold">{t('TextToUs')}</p>
                  <p className="text-[15px] mt-3">{webData?.email}</p>
                </div>
              </motion.div>
            </div>
            <div className="flex flex-col mt-4">
              <motion.div
                className="flex flex-row text-[25px]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="text-stone-900 dark:text-orange-400 pt-2 mr-4"
                />
                <div>
                  <p className="flex justify-start items-center font-semibold">{t('VisitUs')}</p>
                  <p className="text-[15px] mt-3">
                    {webData?.address} {webData?.city} {webData?.postal_code}
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="flex flex-col mt-4">
              <motion.div
                className="flex flex-row text-[25px]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  className="text-stone-900 dark:text-orange-400 pt-2 mr-4"
                />
                <div>
                  <p className="flex justify-start items-center font-semibold">{t('CallUs')}</p>
                  <p className="text-[15px] mt-3">{webData?.phone}</p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        <motion.div
          className="w-full md:w-1/2 h-1/2 md:h-full md:ml-8 md:py-4 md:pr-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <iframe
            src={mapSrc}
            width="100%"
            height="450"
            className="rounded-md"
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps Konum"
          ></iframe>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Contact;
