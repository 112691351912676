import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoBackButton from '../../../common/GoBackButton';
import { useAuth } from '../../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useCountdown } from '../../../../context/CountdownContext';
import CountdownButton from '../../../common/CountdownButton';
import { checkIcon } from '../../../../assets/images';
import { faCircleExclamation, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useNotification } from '../../../../context/NotificationContext';
import { NotificationType, Position } from '../../../../types/enums/notificationEnums';
import Button from '../../../common/Button';

interface InvalidTokenProps {
  email: string;
}

const InvalidToken: React.FC<InvalidTokenProps> = ({ email }) => {
  const { forgotPassword, loadingStates } = useAuth();
  const { showNotification } = useNotification();
  const [errorMessages, setErrorMessages] = useState<string>();
  const { t } = useTranslation();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { countdownTime, isCountdownActive, startCountdown, resetCountdown } = useCountdown();

  const performForgotPassword = async (email: string) => {
    const errorMessage = await forgotPassword(email);
    if (errorMessage) {
      setErrorMessages(t(errorMessage));
    } else {
      setIsEmailSent(true);
      startCountdown(180);
    }
  };

  const handleRequestNewEmail = async () => {
    if (!isCountdownActive) {
      await performForgotPassword(email);
      if (errorMessages) {
        showNotification(errorMessages, NotificationType.Error, Position.TopRight);
      }
    } else {
      setIsEmailSent(true);
    }
  };

  const handleResendEmail = async () => {
    if (!isCountdownActive) {
      await performForgotPassword(email);
      if (errorMessages) {
        showNotification(errorMessages, NotificationType.Error, Position.TopRight);
      }
    }
  };

  const handleCountdownEnd = () => {
    resetCountdown();
  };

  return (
    <div className="flex flex-col lg:flex-row w-full h-screen justify-center items-center">
      <div className="hidden absolute top-0 left-0 w-full lg:flex justify-center items-center h-28 pb-4">
        <Link to="/">
          <p className="text-5xl font-bold bg-gradient-to-r from-amber-500 via-orange-500 to-red-500 dark:from-amber-200 dark:via-orange-400 dark:to-red-400 bg-clip-text text-transparent leading-tight select-none">
            solylock
          </p>
        </Link>
      </div>
      <div className="lg:mt-10 w-full flex items-center justify-center xl:border lg:border-slate-300 dark:lg:border-stone-700 lg:rounded-lg lg:bg-slate-100 dark:lg:bg-stone-800 lg:w-1/2 xl:w-1/3 p-6 md:p-12 lg:p-8 shadow-md">
        {isEmailSent ? (
          <div className="w-full max-w-sm flex flex-col items-center">
            <div>
              <img
                src={checkIcon}
                alt="email sent"
                className="w-16 h-16 md:w-20 md:h-20 mx-auto mb-4 md:mb-8 select-none"
              />
              <p className="text-left text-base md:text-lg text-stone-900 dark:text-zinc-200 mb-10">
                {t('Password reset link has been sent successfully to ')}
                <span>
                  <strong>{email}</strong>
                </span>
                {t(' .')}
              </p>
              <div>
                <p className="mt-4 mb-4 text-sm md:text-base text-stone-900 dark:text-zinc-200 font-light text-left before:content-['*'] before:mr-1">
                  {t('Please check your inbox or spam folder.')}
                </p>
                <div>
                  <CountdownButton
                    initialTime={countdownTime}
                    onCountdownEnd={handleCountdownEnd}
                    onClick={handleResendEmail}
                    className="mt-2"
                  >
                    {loadingStates.forgotPassword ? t('Sending again...') : t('Send again')}
                  </CountdownButton>
                </div>
              </div>
              <GoBackButton to="/login" className="mt-8 text-stone-900 hover:text-gray-600 dark:text-zinc-300">
                {t('Go back to sign in')}
              </GoBackButton>
            </div>
          </div>
        ) : (
          <div className="w-full max-w-md flex flex-col items-center">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="h-20 w-20 mb-4 text-yellow-400"
            />
            <h1 className="text-2xl md:text-3xl text-left text-stone-900 dark:text-zinc-200 mb-8">
              {t('The connection you are trying to access has expired.')}
            </h1>
            <p className="text-stone-900 dark:text-zinc-200 text-justify text-sm md:text-base">
              {t(
                'For security reasons, password reset links expire after a short period. If your link has expired and you still need to reset your password, you can request a new reset email.'
              )}
            </p>
            <div className="mt-12 w-full max-w-md flex flex-col items-start">
              <Button
                variant="tertiary"
                className="py-2 text-lg font-bold w-full"
                type="submit"
                onClick={handleRequestNewEmail}
                disabled={loadingStates.forgotPassword}
              >
                {loadingStates.forgotPassword
                  ? t('Requesting a new reset email...')
                  : t('Request a new reset email')}
              </Button>
            </div>
            <GoBackButton to="/login" className="mt-6 text-stone-900 hover:text-gray-600 dark:text-zinc-300">
              {t('Go back to sign in')}
            </GoBackButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvalidToken;
