import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import './i18n';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/tailwind.css';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { CountdownProvider } from './context/CountdownContext';
import { NotificationProvider } from './context/NotificationContext';
import { CartProvider } from './context/CartContext';
import { WebSettingsProvider } from './context/WebSettingsContext';
import { ConsentProvider } from './context/CookieConsentContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

const REACT_APP_GOOGLE_CLIENT_ID="1013164912023-u792gc36dov8iotn0a0g4qh8crbghr0f.apps.googleusercontent.com"

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
      <ConsentProvider>
        <AuthProvider>
          <CountdownProvider>
            <NotificationProvider>
              <CartProvider>
                <App />
              </CartProvider>
            </NotificationProvider>
          </CountdownProvider>
        </AuthProvider>
      </ConsentProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
