import React from 'react';
import Icons from './icons';
import { SidebarMobileProps } from '../../../types/sidebar';

const SidebarMobile: React.FC<SidebarMobileProps> = ({
  setMobileCart,
  mobileCart,
  selectedIcon,
  setSelectedIcon
}) => {
  const direction = 'row';

  return (
    <div className="flex flex-row md:hidden w-screen h-20 md:h-24 bg-slate-200 dark:bg-stone-700 fixed -bottom-0.5 p-2  items-center justify-center shadow-md shadow-gray-500/20">
      <Icons
        direction={direction}
        setMobileCart={setMobileCart}
        isMobile={true}
        mobileCart={mobileCart}
        selectedIcon={selectedIcon}
        setSelectedIcon={setSelectedIcon}
      />
    </div>
  );
};

export default SidebarMobile;