import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../context/AuthContext';
import { useCountdown } from '../../../../context/CountdownContext';
import { useNotification } from '../../../../context/NotificationContext';
import { NotificationType, Position } from '../../../../types/enums/notificationEnums';

const useLoginPageLogic = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [modalState, setModalState] = useState({
    isForgotPasswordModalOpen: false,
    isEmailSent: false
  });
  const [loginErrorMessages, setLoginErrorMessages] = useState<{ [key: string]: string }>({});
  const [modalErrorMessages, setModalErrorMessages] = useState<{ [key: string]: string }>({});
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const {
    login,
    forgotPassword,
    loadingStates,
    googleLogin,
    socialLoginResult,
    setSocialLoginResult
  } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();

  const { countdownTime, isCountdownActive, startCountdown, resetCountdown } = useCountdown();

  useEffect(() => {
    if (socialLoginResult) {
      if (socialLoginResult.success) {
        navigate('/');
        showNotification(
          t('You have been logged in successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
      } else if (socialLoginResult.error) {
        showNotification(t(socialLoginResult.error), NotificationType.Error, Position.TopRight);
      }
    }
    setSocialLoginResult(null);
  }, [socialLoginResult, navigate, showNotification, t]);

  const validateEmailAndPassword = (email: string, password: string) => {
    const errors: { [key: string]: string } = {};
    if (!email) errors.email = t('Email is required');
    if (!password) errors.password = t('Password is required');
    return errors;
  };

  const performForgotPassword = async (email: string) => {
    try {
      const errorMessage = await forgotPassword(email);
      if (errorMessage) {
        setModalErrorMessages({ general: t(errorMessage) });
      } else {
        setModalState({ ...modalState, isEmailSent: true });
        startCountdown(180);
      }
    } catch (error) {
      showNotification(
        t('An error occurred while sending the password reset link.'),
        NotificationType.Error,
        Position.TopRight
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validateEmailAndPassword(email, password);
    setLoginErrorMessages(errors);
    if (Object.keys(errors).length === 0) {
      const errorMessage = await login(email, password, rememberMe);
      if (errorMessage) {
        setLoginErrorMessages({ ...errors, general: t(errorMessage) });
      } else {
        navigate('/');
        showNotification(
          t('You have been logged in successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
      }
    }
  };

  const handleForgotPasswordClick = () => {
    setModalState({ ...modalState, isForgotPasswordModalOpen: true });
  };

  const closeModal = () => {
    setModalState({ isForgotPasswordModalOpen: false, isEmailSent: false });
  };

  const handleForgotPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = e.currentTarget.email.value;
    const errors: { [key: string]: string } = {};
    if (!email) errors.email = 'Email is required';
    setModalErrorMessages(errors);
    setEmail(email);
    if (Object.keys(errors).length === 0) {
      if (!isCountdownActive) {
        await performForgotPassword(email);
      } else {
        setModalState({ ...modalState, isEmailSent: true });
      }
    }
  };

  const handleResendEmail = async () => {
    if (!isCountdownActive) {
      await performForgotPassword(email);
    }
  };

  const handleCountdownEnd = () => {
    resetCountdown();
  };

  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const handleGoogleSignInClick = () => {
    googleLogin();
  };

  const handleAppleSignInClick = () => {};

  const loginFields = [
    {
      name: 'email',
      type: 'email',
      label: t('Email'),
      placeholder: t('Email'),
      id: 'login-email',
      htmlFor: 'login-email',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
    },
    {
      name: 'password',
      type: 'password',
      label: t('Password'),
      placeholder: t('Password'),
      id: 'login-password',
      htmlFor: 'login-password',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
    }
  ];

  const forgotPasswordFields = [
    {
      name: 'email',
      type: 'email',
      label: t('Email'),
      placeholder: t('Email'),
      id: 'email-forgot-password',
      htmlFor: 'email-forgot-password'
    }
  ];

  return {
    t,
    modalState,
    loginErrorMessages,
    modalErrorMessages,
    loadingStates,
    email,
    rememberMe,
    loginFields,
    forgotPasswordFields,
    countdownTime,
    isCountdownActive,
    handleSubmit,
    handleForgotPasswordClick,
    closeModal,
    handleForgotPasswordSubmit,
    handleRememberMeChange,
    handleGoogleSignInClick,
    handleAppleSignInClick,
    handleResendEmail,
    handleCountdownEnd
  };
};

export default useLoginPageLogic;
