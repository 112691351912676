import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, RefreshCw, Gift } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface UpdateModalContentProps {
  onClose: () => void;
  onRegister: () => void;
}

const UpdateModalContent: React.FC<UpdateModalContentProps> = ({ onClose, onRegister }) => {
  const { t } = useTranslation();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        duration: 0.5
      }
    }
  };

  const features = [
    t('Enhanced security measures'),
    t('Improved user experience'),
    t('New personalization options'),
    t('Better performance')
  ];

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="space-y-6"
    >
      {/* Header Section */}
      <motion.div variants={itemVariants} className="flex items-start space-x-4">
        <div className="bg-orange-100 dark:bg-orange-900 p-3 rounded-full">
          <RefreshCw className="w-6 h-6 text-orange-500 dark:text-orange-400" />
        </div>
        <div>
          <h3 className="text-lg font-semibold text-stone-900 dark:text-white mb-2">
            {t('Welcome to Our Updated Platform!')}
          </h3>
          <p className="text-stone-600 dark:text-zinc-300">
            {t(
              'We have made significant improvements to enhance your experience. To continue enjoying our services, please take a moment to re-register your account.'
            )}
          </p>
        </div>
      </motion.div>

      {/* Features List */}
      <motion.div
        variants={itemVariants}
        className="bg-slate-100 dark:bg-stone-800 rounded-lg p-4 space-y-4"
      >
        <div className="flex items-center gap-2">
          <Gift className="w-5 h-5 text-orange-500 dark:text-orange-400" />
          <h4 className="font-semibold text-stone-900 dark:text-zinc-200">
            {t('Exciting New Features')}
          </h4>
        </div>

        <ul className="space-y-3">
          {features.map((feature, index) => (
            <motion.li
              key={index}
              variants={{
                hidden: { opacity: 0, x: -20 },
                visible: {
                  opacity: 1,
                  x: 0,
                  transition: { delay: index * 0.1 }
                }
              }}
              className="flex items-center gap-2 text-stone-700 dark:text-zinc-300"
            >
              <div className="w-1.5 h-1.5 bg-orange-500 dark:bg-orange-400 rounded-full" />
              {feature}
            </motion.li>
          ))}
        </ul>
      </motion.div>

      {/* Action Buttons */}
      <motion.div variants={itemVariants} className="flex flex-col sm:flex-row gap-3 pt-2">
        <button
          onClick={onRegister}
          className="flex items-center justify-center gap-2 px-4 py-2.5 bg-orange-500 hover:bg-orange-600 dark:bg-orange-600 dark:hover:bg-orange-700 text-white rounded-lg transition-colors flex-1"
        >
          {t('Re-Register Now')}
          <ArrowRight className="w-4 h-4" />
        </button>
        <button
          onClick={onClose}
          className="px-4 py-2.5 border border-stone-300 dark:border-stone-600 text-stone-700 dark:text-zinc-300 rounded-lg hover:bg-slate-100 dark:hover:bg-stone-800 transition-colors flex-1"
        >
          {t('Remind Me Later')}
        </button>
      </motion.div>
    </motion.div>
  );
};

export default UpdateModalContent;
