import axios from 'axios';

const api = axios.create({
  baseURL: 'https://foodappapi.solylock.dev/api/v1',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 10000
});

// Request interceptor
api.interceptors.request.use(
  config => {
    // You can add logic here to attach tokens from local storage if needed
    // const token = localStorage.getItem('token');
    // if (token) {
    //   config.headers['Authorization'] = `Bearer ${token}`;
    // }
    //
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  response => {
    // You can do something with the response data here
    return response;
  },
  error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      switch (error.response.status) {
        case 401:
          // Unauthorized - you might want to redirect to login
          console.log('Unauthorized, please login');
          // Add your redirect logic here
          break;
        case 403:
          // Forbidden
          console.log('You do not have permission to access this resource');
          break;
        case 404:
          // Not Found
          console.log('The requested resource was not found');
          break;
        case 500:
          // Server Error
          console.log('There was a server error');
          break;
        default:
          console.log('An error occurred:', error.response.data);
      }
    } else if (error.request) {
      console.log('No response received:', error.request);
    } else {
      console.log('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
