import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faClose,
  faMinus,
  faPlus,
  faShoppingCart,
  faTicket,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Button from '../common/Button';
import { useAuth } from '../../context/AuthContext';
import { CartProps } from '../../types/cart';
import { useCart } from '../../context/CartContext';
import Spinner from '../common/Spinner';
import { motion, AnimatePresence } from 'framer-motion';
import { useWebSettings } from '../../context/WebSettingsContext';

const Sidecart: React.FC<CartProps> = ({ mobileCart, setMobileCart, setSelectedIcon }) => {
  const [delivery, setDelivery] = useState(true);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1280);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { openData } = useWebSettings();
  const {
    cartItems,
    removeItemFromCart,
    setCartItems,
    clearCart,
    confirmCart,
    calculateSubTotal,
    calculateDiscountedPrice,
    loading
  } = useCart();
  const { webData, loading: webLoading } = useWebSettings();
  const [shouldAnimate, setShouldAnimate] = useState(() => {
    return !sessionStorage.getItem('hasAnimated');
  });
  const isImage = webData?.include_image === 1;
  const location = window.location;

  useEffect(() => {
    if (shouldAnimate) {
      sessionStorage.setItem('hasAnimated', 'true');
    }

    const handleBeforeUnload = () => {
      sessionStorage.removeItem('hasAnimated');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldAnimate]);

  useEffect(() => {
    const handleResize = () => {
      const newIsDesktop = window.innerWidth >= 1280;
      setIsDesktop(newIsDesktop);

      if (newIsDesktop && mobileCart) {
        setMobileCart(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [mobileCart, setMobileCart]);

  const handleQuantityChange = (itemId: number, change: number) => {
    setCartItems(prevItems =>
      prevItems.map(item => {
        if (item.id === itemId) {
          const newQuantity = Math.max(1, item.quantity + change);
          const newPrice =
            calculateDiscountedPrice(item.price, item.product_discount, item.category_discount) *
            newQuantity;
          return { ...item, quantity: newQuantity, totalPrice: newPrice };
        }
        return item;
      })
    );
  };

  const handleConfirmCart = async () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const success = await confirmCart();
    if (success && cartItems.length > 0) {
      navigate('/payment');
    }
  };

  const handleDeleteCartItem = async (itemId: number) => {
    await removeItemFromCart(itemId);
  };

  const handleClearCart = async () => {
    await clearCart();
  };

  const handleCloseMobileCart = () => {
    const pathToIconMap: { [key: string]: string } = {
      '/': 'homeIcon',
      '/order-history': 'historyIcon',
      '/basket': 'basket',
      '/settings': 'settingsIcon',
      '/login': 'loginIcon'
    };
    setMobileCart(false);
    const icon = pathToIconMap[location.pathname] || 'homeIcon';
    setSelectedIcon(icon);
  };

  const shouldShowCart = isDesktop || mobileCart;

  const cartVariants = {
    hidden: { x: '100%' },
    visible: {
      x: 0,
      transition: { type: 'spring', stiffness: 300, damping: 30 }
    },
    exit: {
      x: '100%',
      transition: { type: 'spring', stiffness: 300, damping: 30 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 300, damping: 30 }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.2 }
    }
  };

  return (
    <>
      <motion.div
        className={clsx(
          'hidden fixed w-24 xl:hidden text-orange-500 text-center text-3xl flex-col justify-between gap-4 bg-slate-100 dark:bg-stone-800 px-2 py-4 h-screen overflow-x-hidden right-0',
          {
            hidden: mobileCart || isDesktop,
            'md:flex': !mobileCart && !isDesktop
          }
        )}
        initial={isDesktop ? (shouldAnimate ? 'hidden' : 'visible') : 'hidden'}
        animate="visible"
        exit="exit"
        variants={cartVariants}
      >
        <FontAwesomeIcon
          icon={faShoppingCart}
          className="mt-4 cursor-pointer"
          onClick={() => setMobileCart(true)}
        />
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="mb-4 cursor-pointer"
          onClick={() => setMobileCart(true)}
        />
      </motion.div>
      <AnimatePresence>
        {shouldShowCart && (
          <motion.div
            className={clsx(
              'min-h-screen fixed w-full md:w-96 xl:w-[26rem] py-8 px-3 md:p-8 right-0 top-0 bg-slate-200 dark:bg-stone-800 flex flex-col overflow-x-hidden overflow-y-hidden shadow-lg shadow-gray-500/20',
              {
                'block fixed': mobileCart && !isDesktop,
                'hidden xl:block': !mobileCart || isDesktop
              }
            )}
            initial={isDesktop ? (shouldAnimate ? 'hidden' : 'visible') : 'hidden'}
            animate="visible"
            exit="exit"
            variants={cartVariants}
          >
            <div className="flex justify-between mb-4 text-xl font-bold items-center">
              <h2 className="text-stone-700 dark:text-white">{t('Your Cart')}</h2>
              {!isDesktop && (
                <FontAwesomeIcon
                  icon={faClose}
                  className="text-orange-500 cursor-pointer text-2xl hover:text-3xl hover:text-white transition-all mr-2.5"
                  onClick={handleCloseMobileCart}
                />
              )}
            </div>
            <div className="flex flex-row">
              <Button
                variant={delivery ? 'primary' : 'secondary'}
                className="mr-3"
                onClick={() => setDelivery(true)}
              >
                {t('Delivery')}
              </Button>
              <Button variant={!delivery ? 'primary' : 'secondary'} className="mr-3" disabled>
                {t('To Go')}
              </Button>
            </div>
            <div className="border-t border-gray-300 dark:border-stone-700 h-[22rem] md:h-[27rem] lg:h-[32rem] xl:h-[32.5rem] pt-0 pb-10 md:pb-0 mt-6 overflow-y-auto">
              {loading || webLoading ? (
                <motion.div
                  className="mt-10 flex justify-center items-start h-full"
                  initial={
                    isDesktop ? (shouldAnimate ? { opacity: 0 } : { opacity: 1 }) : { opacity: 1 }
                  }
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Spinner />
                </motion.div>
              ) : cartItems.length === 0 ? (
                <motion.div
                  className="mt-10 flex justify-center items-start h-full"
                  initial={
                    isDesktop ? (shouldAnimate ? { opacity: 0 } : { opacity: 1 }) : { opacity: 1 }
                  }
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <p className="text-slate-400 dark:text-stone-500">
                    {t('Your cart is currently empty.')}
                  </p>
                </motion.div>
              ) : (
                <table className="w-full text-md text-left rtl:text-right text-stone-700 dark:text-white mt-4">
                  <tbody className="mt-2 z-0 overflow-y-auto overflow-x-hidden">
                    <AnimatePresence>
                      {cartItems.map(item => (
                        <motion.tr
                          key={item.id}
                          className="items-center justify-center"
                          variants={shouldAnimate ? itemVariants : {}}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                        >
                          <td className="py-2 flex flex-row items-center">
                            {isImage && (
                              <div className="w-24 h-24">
                                <img
                                  src={item.image_url}
                                  alt={item.product_name}
                                  className="rounded-xl h-full w-full object-contain select-none"
                                />
                              </div>
                            )}
                            <div className="ml-2 flex flex-col">
                              <span>{item.product_name}</span>
                              <span className="font-normal select-none">
                                {calculateDiscountedPrice(
                                  item.price,
                                  item.product_discount,
                                  item.category_discount
                                ).toFixed(2)}
                                € x{' '}
                                <span className="bg-orange-500 py-0.5 px-1.5 rounded-3xl font-bold text-white">
                                  {item.quantity}
                                </span>{' '}
                                Qty
                              </span>
                              <div className="mt-3 flex flex-wrap gap-1">
                                <Button variant="secondary" className="text-xs mr-2 cursor-default">
                                  {item.size_name}
                                </Button>
                                {item.quantity > 1 && (
                                  <Button
                                    variant="secondary"
                                    className="text-xs mr-1"
                                    onClick={() => handleQuantityChange(item.id, -1)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faMinus}
                                      className="text-stone-700 dark:text-orange-500"
                                    />
                                  </Button>
                                )}
                                <Button
                                  variant="secondary"
                                  className="text-xs mr-3"
                                  onClick={() => handleQuantityChange(item.id, 1)}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-stone-700 dark:text-orange-500"
                                  />
                                </Button>
                                <Button
                                  variant="secondary"
                                  className="text-xs"
                                  onClick={() => handleDeleteCartItem(item.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashCan}
                                    className="text-stone-700 dark:text-orange-500"
                                  />
                                </Button>
                              </div>
                            </div>
                          </td>
                          <td className="py-2">
                            {(
                              calculateDiscountedPrice(
                                item.price,
                                item.product_discount,
                                item.category_discount
                              ) * item.quantity
                            ).toFixed(2)}
                            €
                          </td>
                        </motion.tr>
                      ))}
                    </AnimatePresence>
                  </tbody>
                </table>
              )}
            </div>
            <motion.div
              className="lg:flex flex-col pb-28 md:pb-5 absolute bottom-0 right-0 left-0 w-full px-4 md:px-8 z-10 bg-slate-100 dark:bg-stone-800"
              initial={shouldAnimate ? { opacity: 0, y: 20 } : { opacity: 1, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <form className="flex items-center justify-center relative dark:border-stone-700 pt-6 gap-2">
                <span className="absolute left-3 top-1/2 text-stone-500 dark:text-stone-400 text-md">
                  <FontAwesomeIcon icon={faTicket} className="opacity-50" />
                </span>
                <input
                  type="text"
                  className="pl-10 pr-4 py-2 text-md rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full border border-gray-400 dark:border-0 opacity-50 cursor-not-allowed"
                  placeholder={t('Discount code')}
                  disabled
                />
                <Button
                  variant="tertiary"
                  className="w-1/3 opacity-50 cursor-not-allowed"
                  type="submit"
                  disabled
                >
                  {t('Redeem')}
                </Button>
              </form>
              <div className="flex justify-between mt-2">
                <span className="text-lg text-stone-700 dark:text-stone-400">{t('Discount')}</span>
                <span className="text-lg text-stone-600 dark:text-stone-300">0€</span>
              </div>
              <div className="flex justify-between mt-2">
                <span className="text-lg text-stone-700 dark:text-stone-400">{t('Sub total')}</span>
                <span className="text-lg text-stone-600 dark:text-stone-300">
                  {calculateSubTotal().toFixed(2)}€
                </span>
              </div>
              <div className="flex flex-col">
                <Button
                  variant="cancel"
                  className="mt-4 cursor-pointer"
                  onClick={handleClearCart}
                  disabled={cartItems.length === 0 || loading}
                >
                  {t('Clear the Cart')}
                </Button>
                <Button
                  variant="tertiary"
                  className="mt-4 cursor-pointer"
                  onClick={handleConfirmCart}
                  disabled={cartItems.length === 0 || loading || !openData?.open}
                >
                  {t('Confirm the Cart')}
                </Button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidecart;
