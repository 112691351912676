import React from 'react';
import { SvgIconProps } from '../../../types/svg';
import { motion } from 'framer-motion';

interface ContactIconProps extends SvgIconProps {
  shouldAnimate?: boolean;
}

const ContactIcon: React.FC<ContactIconProps> = ({
  color = '#FFFFFF',
  height = '26px',
  width = '26px',
  onClick,
  className = 'cursor-pointer',
  shouldAnimate
}) => {
  const contactIconVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: { type: 'spring', stiffness: 300, damping: 20 }
    },
    hover: { scale: 1.1 }
  };
  return (
    <motion.svg
      initial={shouldAnimate ? 'hidden' : 'visible'}
      animate="visible"
      whileHover="hover"
      variants={contactIconVariants}
      className={className}
      height={height}
      width={width}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="1.5"></circle>{' '}
        <path d="M12 17V11" stroke={color} strokeWidth="1.5" strokeLinecap="round"></path>{' '}
        <circle cx="1" cy="1" r="0.5" transform="matrix(1 0 0 -1 11 9)" fill={color}></circle>{' '}
      </g>
    </motion.svg>
  );
};

export default ContactIcon;
